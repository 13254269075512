import consumer from "./consumer"
import CableReady from 'cable_ready'

consumer.subscriptions.create("AcSyncProgressChannel", {
  connected() {
    console.log("Connected to the server");
  },

  disconnected() {
    console.log("Disconnected from the server");
  },

  received: data => {
    if (data.cableReady) {
      if (data.operations.dispatchEvent && data.operations.dispatchEvent[0].name == 'finished') {
        location.reload();
      } else {
        CableReady.perform(data.operations)
      }
    }
  }
});
